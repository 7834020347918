
import SaveButton from '../save-button.vue';
import LoadingSpinner from '../loading-spinner.vue';
import ModalHeader from '~/components/modal-header.vue';

export default {
    name: 'PushContactsToSessionsModal',
    components: {
        LoadingSpinner,
        SaveButton,
        ModalHeader,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            selectedContactGroup: null,
            selectedType: 'Company Rep',
            contactsLoaded: false,
            contactsWithoutCompanySessionsId: [],
            contactsWithoutEmail: [],
            contactsInactive: [],
            contactsToEvent: [],
            contactsToSessions: [],
            syncErrors: [],
            eventHasContactGroup: false,
            eventUpdated: false,
            syncWithoutCompany: false,
        };
    },
    watch: {
        selectedContactGroup(cg) {
            if (!cg) {
                this.contactsLoaded = false;
                return;
            }
            const existingGroupIds = this.event.contactLists.map((cg) => cg.id);
            this.eventHasContactGroup = existingGroupIds.includes(cg.id);

            this.$axios.get(`/api/events/${this.event.id}/sessions_contacts_sync_check/${cg.id}`).then((res) => {
                this.contactsToEvent = res.data.event;
                this.contactsToSessions = res.data.sessions;
                this.contactsWithoutCompanySessionsId = res.data.withoutCompanySessionsId;
                this.contactsWithoutEmail = res.data.withoutEmail;
                this.contactsInactive = res.data.inactive;
                this.contactsLoaded = true;
            });
        },
    },
    mounted() {
        // cannot use unmounted because the component stays in the DOM
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (this.eventUpdated) {
                this.$emit('event-updated');
            }
        });
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            const toastId = this.generateUUID();

            this.$axios
                .post(`/api/events/${this.event.id}/sessions_contacts_sync_trigger/${this.selectedContactGroup.id}`, {
                    type: this.selectedType,
                    withoutCompany: this.syncWithoutCompany,
                })
                .then(() => {
                    this.addToast({
                        type: 'success',
                        title: 'Adding successful',
                        message: 'The contacts from the list have been pushed to Sessions and added to the event.',
                        id: toastId,
                    });
                    this.$emit('event-updated');
                })
                .catch((e) => {
                    const res = e.response;
                    this.syncErrors = res.data.errors ?? [];
                    this.addToast({
                        type: 'danger',
                        title: `Adding ${res.data.synced > 0 ? 'partially' : ''} failed`,
                        message: 'When syncing contacts to Sessions, some contacts could not be added.',
                        id: toastId,
                    });
                })
                .finally(() => {
                    this.loading = false;
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                });
        },

        addGroupToEvent() {
            this.$axios
                .post(`/api/events/${this.event.id}/add_contact_group/${this.selectedContactGroup.id}`)
                .then((res) => {
                    const toastId = this.generateUUID();
                    const toastData = {
                        message: res.data.message,
                        id: toastId,
                    };
                    if (res.data.success) {
                        toastData.type = 'success';
                        toastData.title = 'Adding successful';
                        this.eventHasContactGroup = true;
                        this.eventUpdated = true;
                    } else {
                        toastData.type = 'danger';
                        toastData.title = 'Adding failed';
                    }
                    this.addToast(toastData);
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                });
        },
    },
};
