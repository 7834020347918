import { render, staticRenderFns } from "./personal-information-card.vue?vue&type=template&id=32ac8245&scoped=true"
import script from "./personal-information-card.vue?vue&type=script&lang=js"
export * from "./personal-information-card.vue?vue&type=script&lang=js"
import style0 from "./personal-information-card.vue?vue&type=style&index=0&id=32ac8245&prod&scoped=true&lang=scss"
import style1 from "./personal-information-card.vue?vue&type=style&index=1&id=32ac8245&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ac8245",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConfirmModal: require('/codebuild/output/src3295261676/src/web/components/confirm-modal.vue').default,TableActions: require('/codebuild/output/src3295261676/src/web/components/table-actions.vue').default,LoadingSpinner: require('/codebuild/output/src3295261676/src/web/components/loading-spinner.vue').default})
